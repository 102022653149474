@font-face {
  font-family: "Poppins-Regular";
  src: url(./assets/fonts/Poppins-Regular.woff);
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "Poppins-Bold";
  src: url(./assets/fonts/Poppins-Bold.woff);
}
@font-face {
  font-family: "Poppins-Light";
  src: url(./assets/fonts/Poppins-Light.woff);
}
@font-face {
  font-family: "Thuoc";
  src: url(./assets/fonts/Thuoc.ttf);
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 100 700;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v14/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
    format("woff2");
}
* {
  font-family: "Poppins-Regular";
}
html {
  scroll-behavior: smooth;
}
.LastButton {
  background-color: #0F6F70;
  border-radius: 40px;
  padding: 15px 20px !important;
  font-family: "Poppins-Regular";
  color: #fff !important;
  font-size: 14px !important;
  border: none;
}
textarea:focus, input:focus{
  outline: none;
}.slick-next:before,
.slick-prev:before {
  font-size: 0px;
  color: #707070;
}
.slick-prev {
  left: 0px;
  display: none;
}